<template>
	<div>
		<div class="queryBox mb20 ">
			<div class="mb20">
				<span class="mr20">共{{payList.total_count}}个</span>
				<span class="mr20">启用： {{payList.enable_count}}个</span>
				<span class="mr20">停用： {{payList.total_count - payList.enable_count}}个</span>
				<span class="mr20">在线： {{payList.online_count}}个</span>
			</div>
			<div class="mb20">
				<el-button class="mr20" size="mini" type="primary" @click="newqr('new')">新增</el-button>
				<el-button class="mr20" size="mini" type="warning" @click="stateAll(0)" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量停用
				</el-button>
				<el-button class="mr20" size="mini" type="success" @click="stateAll(1)" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量启用
				</el-button>
				<el-button size="mini" type="danger" @click="deleteAll()" v-if="false"
					:disabled="disLoading || operating || tableData.length == 0 || multipleSelection.length == 0">批量删除
				</el-button>
			</div>
			<div class="flexX flexcenter">
				<span>状态：</span>
				<el-select size="mini" class="mr20" v-model="state" clearable placeholder="请选择状态">
					<el-option v-for="(item, index) in stateArr" :key="item.key" :label="item.val" :value="item.key">
					</el-option>
				</el-select>
				<!-- <span>金额：</span>
				<el-select size="mini" class="mr20" v-model="money" clearable placeholder="请选择金额">
					<el-option v-for="(item) in moneyArr" :key="item" :label="item" :value="item"></el-option>
				</el-select> -->
				<el-button size="mini" type="primary" :icon="disLoading ? 'el-icon-loading' : 'el-icon-search'" @click="getData"
					:disabled="disLoading">查询</el-button>
			</div>
		</div>
		<template>
			<el-table :max-height="height" :data="tableData" border="" stripe style="width: 100%" v-loading="loading"
				@selection-change="handleSelectionChange" size="mini" :show-overflow-tooltip="true">
				<el-table-column type="selection" align="center" width="55"></el-table-column>
				<el-table-column type="index" label="序号" align="center" width="120px">
					<template slot-scope="scope">
						<div class="errorDiv" v-if="scope.row.id == index" v-for="(item, index) in errorArr">{{ item }}
						</div>
						<span>{{ scope.$index + 1 }}</span>
					</template>
				</el-table-column>
				<el-table-column prop="account" label="钱包编号" align="center"></el-table-column>
				<el-table-column prop="true_name" label="姓名" align="center"></el-table-column>
				<el-table-column prop="bank_name" label="所属银行" align="center"></el-table-column>
				<el-table-column prop="eve_used_money" label="日限额" align="center"></el-table-column>
				<el-table-column prop="year_total_money" label="年限额" align="center"></el-table-column>
				<el-table-column prop="telephone" label="手机号码" align="center"></el-table-column>				
				<el-table-column prop="callback_id" label="监控ID" align="center"></el-table-column>
				
				<el-table-column label="启停状态" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.state == 0 ? 'csuccess' : 'cdanger'">{{ scope.row.state == 0 ? '启用' : '禁用' }}</span>
					</template>
				</el-table-column>
				<el-table-column label="在线状态" align="center">
					<template slot-scope="scope">
						<span :class="scope.row.is_online == 1 ? 'csuccess' :scope.row.is_online == 2?'cwarning':'cdanger'">{{  scope.row.is_online == 1?'在线': scope.row.is_online == 2?'掉线':'离线'}}</span>
					</template>
				</el-table-column>				
				<el-table-column label="二维码" align="center">
					<template slot-scope="scope">
						<div class="textCenter">
							<span v-if='scope.row.qrcode_url' @click="showQr(scope.row.qrcode_url)" class="qrIcon"></span>
						</div>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center">
					<template slot-scope="scope">
						<div style="white-space: nowrap;">
							<el-link type="primary" class="mr20" @click="edit(scope.row)">编辑</el-link>
							<el-button :disabled="operating" :type="scope.row.state == 0 ? 'warning' : 'success'" size="mini"
								@click="setState(scope.row)">{{ scope.row.state == 0 ? '停用' : '启用' }}</el-button>
							<el-button :disabled="operating" type="danger" size="mini" @click="deleteQr(scope.row)" v-if="false">删除
							</el-button>
						</div>
					</template>
				</el-table-column>
			</el-table>
		</template>

		<div class="flexX  mt20 pageBox">
			<el-pagination :hide-on-single-page="true" @size-change="handleSizeChange" @current-change="handleCurrentChange"
				:current-page="page" :page-sizes="pageSizesArr" :page-size="pageSize"
				layout="total, sizes, prev, pager, next, jumper" :total="total"></el-pagination>
		</div>
		<el-dialog  center class="dialog" top="150px" :visible.sync="dialogVisible" @close="close" width="30%"
			:close-on-click-modal="closeModal" :title="dialogTitle">
			<div class="formBox" v-loading="dialogloading">
				<div class="textCenter">
					<div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">钱包编号:</span>
							<el-input size="mini" style="width: 400px;" type="text" placeholder="请输入钱包编号"
								v-model="dataDetails.account"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">所属银行：</span>
							<el-input size="mini" style="width: 400px;" type="text" placeholder="请输入银行名称"
								v-model="dataDetails.bank_name"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">姓名：</span>
							<el-input size="mini" style="width: 400px;" type="text" placeholder="请输入姓名"
								v-model="dataDetails.true_name"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">日限额：</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入日限额"
								v-model="dataDetails.eve_used_money"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">年限额:</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入日限额"
								v-model="dataDetails.year_total_money"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">手机号码：</span>
							<el-input size="mini" style="width: 400px;" type="number" placeholder="请输入手机号码"
								v-model="dataDetails.telephone"></el-input>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">状态：</span>
							<el-switch @change="changeAliState" v-model="aliState" active-text="启用"
								inactive-text="禁用"></el-switch>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">监控ID: </span>
							<el-input size="mini" style="width: 400px;" type="text" placeholder="请输入监控ID"
								v-model="dataDetails.callback_id"></el-input>
						</div>

						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">(监控ID)批量启停：</span>
							<el-button class="mr20" size="mini" type="success" @click="setAlipayState('0')">启用 </el-button>
							<el-button size="mini" type="danger" @click="setAlipayState('1')">停用 </el-button>
						</div>
						<div class="flexX flexcenter mb20">
							<span style="width: 100px;">收款二维码：</span>
							<div>
								<el-button class="mr20" size="mini" type="success" @click="showBigImg()" :disabled="!fileurl">查看大图 </el-button>
							</div>
							<el-upload action="" :show-file-list="false" :http-request="resolveQR">
								<img slot="trigger" v-if="fileurl" class="upload-img" :src="fileurl">
								<el-button v-else type="success">上传</el-button>
							</el-upload>
						</div>
					</div>

					<el-button size="mini" type="primary" @click="sure()">确定</el-button>
					<el-button size="mini" @click="close">关闭</el-button>
				</div>
			</div>
			<el-dialog title="收款二维码" top="150px" width ="30%" id ='myDialog' :visible.sync="innerVisibleImg" append-to-body @opened="opened" center>
				<div class="image-dialog">
				<el-image  :src="fileurl"></el-image></div>
			</el-dialog>
		</el-dialog>
		<div @click="closePopup()" class="popup" :class="{ 'show': showPopup }">
			<div id="qr" class="qrcode" ref="qrCodeUrl"></div>
		</div>
	</div>
</template>

<script>
import local from '@/util/local'
import {
	isEmpty,
	isPositiveNumber,
	isMoney,
	isMobile
} from '@/util/validate'
import {
	Message
} from 'element-ui'
import QRCode2 from 'qrcodejs2'
import QrCode from '../../../node_modules/qrcode-decoder/dist/index'
// import QrCode from '../node_modules/qrcode-decoder/dist/index';
export default {
	data() {
		return {
			tableData: [],
			height: 0,
			loading: false,
			disLoading: false,
			pageSizesArr: [20, 50, 100, 200],
			pageSize: 20,
			total: 0,
			page: 1,
			state: '',
			stateArr: [{
				key: '',
				val: "全部"
			},
			{
				key: '0',
				val: "离线"
			},
			{
				key: '1',
				val: "在线"
			},
			{
				key: '2',
				val: "掉线"
			},
			],
			money: '',
			moneyArr: [],
			id: '',
			operating: false, //正在操作
			multipleSelection: [], //选中数组
			showPopup: false,
			errorArr: {},
			fileList: [],
			uploadData: {},
			upErrorArr: [],


			pay_type: 77,
			item: {},
			storeInfo: {},
			dialogTitle: "",
			dialogType: '',
			dialogVisible: false,
			dialogloading: false,
			closeModal: false, //是否点击弹框 关闭，
			dataDetails: {
				true_name: "",
				account_branch: "",
				card: "",
				bank: "",
				telephone: "",
				state: 0,
				province: "",
				city: "",
				login_password: "",
				pay_password: "",
				account: '',
				bank_name: '',
				nickname: '',
				data: {},
				callback_id: '',
				year_total_money: '',
				eve_used_money: '',
				qrcode_url: '',
			},
			innerVisibleImg:false,
			aliState: true,
			fileurl: null,
			payList:{},
		};
	},
	created() {
		this.payTypeAll(40)
		
		if (this.$route.query.id) {
			this.id = this.$route.query.id;
		}
		if (this.$route.query.money) {
			this.money = this.$route.query.money;
		}
		if (this.$route.query.state) {
			this.state = this.$route.query.state;
		}
		this.uploadData = {
			username: local.get('bpcusername'),
			tid: local.get('bpctid'),
			store_id: this.id,
			login_type: 2,
		};
		// this.getStoreMoneyGroup()
		this.alipayList()
		this.$nextTick(() => {
			let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
			let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
			let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
			let documentHeight = document.documentElement.clientHeight
			this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - 20
		})
	},
	filters: {
		tofixed: function (value) {
			if (value || value == 0) {
				// console.log('sssssssssss', (value * 1).toFixed(2) + '')
				// return ((value * 1).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
				let val = Math.floor(((value * 1) * 100).toPrecision(12))
				return ((val / 100).toFixed(2) + '').replace(/(\d)(?=(\d{3})+\.)/g, '$1,')
			}
		}
	},
	methods: {
		payTypeAll(type) {
			let data = {}
			this.$api.payTypeAll(data).then(res=> {
				if(res.status == 1){
					res.data.map(item=>{
						if(item.id == type){
							this.payList =item 
						}
					})
				}
				else{
					this.$message({
						type: 'error',
						message: res.msg
					})
				}					
			})
		},
		
		getStoreMoneyGroup() {
			this.$api.getStoreMoneyGroup({
				store_id: this.id
			}).then(res => {
				if (res.status == 1) {
					res.data.splice(0, 0, '全部')
					this.moneyArr = JSON.parse(JSON.stringify(res.data))

				}
			})
		},
		getData() {
			this.alipayList()
		},
		alipayList() {
			let data = {
				state: this.state,
				pay_type: 40,
				page: this.page,
				size: this.pageSize,
			}
			this.disLoading = true
			this.$api.alipayList(data).then(res => {
				this.loading = false;
				this.disLoading = false;
				if (res.status === 1) {
					this.tableData = res.data.data;
					this.total = res.data.total
				} else {
					this.$message({
						type: 'error',
						message: res.msg
					})
				}
			}).catch(error => {
				console.log(error)
				this.loading = false
				this.disLoading = false
			})
		},
		handleSizeChange(val) {
			this.pageSize = val
			this.page = 1
			this.alipayList()
		},
		handleCurrentChange(val) {
			this.page = val
			this.alipayList()
		},
		handleSelectionChange(val) {
			// console.log(val)
			this.multipleSelection = val;
		},
		deleteAll() {
			let selectArr = this.multipleSelection.filter(item => {
				if (item.id) {
					return item
				}
			})
			let dataId = selectArr.map(item => {
				return item.id
			})
			let data = {
				qr_id: dataId.join(',')
			}
			console.log(data)
			this.deleteStoreQr(data)
		},
		stateAll(state) {
			let selectArr = this.multipleSelection.filter(item => {
				if (item.id) {
					return item
				}
			})
			let dataId = selectArr.map(item => {
				return item.id
			})
			let data = {
				state: state,
				qr_id: dataId.join(',')
			}
			console.log(data)
			this.setStoreQrState(data)
		},
		deleteQr(item) {
			let data = {
				qr_id: item.id
			}
			console.log(data)
			this.deleteStoreQr(data)
		},
		setState(item) {
			this.dataDetails = JSON.parse(JSON.stringify(item))
			this.fileurl = this.dataDetails.qrcode_url
			let data = {
				id: this.dataDetails.id,
				true_name: this.dataDetails.true_name,
				account: this.dataDetails.account,
				bank_name: this.dataDetails.bank_name,
				telephone: this.dataDetails.telephone,
				year_total_money: this.dataDetails.year_total_money,
				eve_used_money: this.dataDetails.eve_used_money,
				qrcode_url: this.dataDetails.qrcode_url,
				state: this.dataDetails.state == 0?1:0,
				callback_id: this.dataDetails.callback_id,
				pay_type: 40,
			}
			this.$api.editAlipay(data).then(res => {
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()
					} else {
						this.close()
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
			}).catch(error=>{
				console.log(error)
				this.close()
			})
		},
		setStoreQrState(data) {
			this.$confirm(`确定改变选择收款码的启停状态吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.operating = true
				console.log('==============')
				this.$api.setStoreQrState(data).then(res => {
					console.log("res")
					this.operating = false
					if (res.status == 1) {
						this.alipayList()
					} else {
						this.errorArr = res.data
					}
				}).catch(error => {
					this.operating = false
				})
			}).catch(() => {
				this.operating = false
				this.$message({
					type: 'info',
					message: '已取消操作'
				});
			});
		},
		deleteStoreQr(data) {
			this.$confirm(`确定删除选择收款码吗?`, '提示', {
				confirmButtonText: '确定',
				cancelButtonText: '取消',
				type: 'warning'
			}).then(() => {
				this.operating = true
				this.$api.deleteStoreQr(data).then(res => {
					this.operating = false
					if (res.status == 1) {
						this.alipayList()
					} else {
						this.errorArr = res.data
					}
				}).catch(error => {
					this.operating = false
				})
			}).catch(() => {
				this.operating = false
				this.$message({
					type: 'info',
					message: '已取消操作'
				});
			});
		},
		showQr(url) {
			this.showPopup = true
			document.getElementById('qr').innerHTML = '';
			var qrcode = new QRCode2(this.$refs.qrCodeUrl, {
				text: url, // 需要转换为二维码的内容
				width: 400,
				height: 400,
				colorDark: '#000000',
				colorLight: '#ffffff',
				correctLevel: QRCode2.CorrectLevel.H
			})
		},
		closePopup() {
			this.showPopup = false
		},
		newqr() {

			let queryData = {}
			queryData['id'] = this.id
			// this.$router.push({
			// 	path: '/passageAlisub2',
			// 	query: queryData,
			// })
			this.dialogType = 'new'
			this.dialogTitle = "新增"
			this.dialogVisible = true
		},

		close() {
			this.dialogVisible = false
			this.dialogloading = false
			this.dialogTitle = ''
			this.dialogType = ""
			this.item = {}
			this.storeInfo = {}
			this.aliState = true
			this.fileurl = null
			this.dataDetails = {
				true_name: "",
				account_branch: "",
				card: "",
				bank: "",
				telephone: "",
				state: 0,
				province: "",
				city: "",
				login_password: "",
				pay_password: "",
				account: '',
				bank_name: '',
				nickname: '',
				data: {},
				callback_id: '',
				year_total_money: '',
				eve_used_money: '',
				qrcode_url: '',
			}
		},
		edit(item) {
			this.dialogType = 'show'
			this.dialogTitle = '编辑'
			this.dataDetails = JSON.parse(JSON.stringify(item))
			this.fileurl = this.dataDetails.qrcode_url
			this.aliState = this.dataDetails.state == 0?true:false
			this.dialogVisible = true

		},
		sure() {
			if(this.dialogType == 'new'){
				let data = {
					true_name: this.dataDetails.true_name,
					account: this.dataDetails.account,
					bank_name: this.dataDetails.bank_name,
					telephone: this.dataDetails.telephone,
					year_total_money: this.dataDetails.year_total_money,
					eve_used_money: this.dataDetails.eve_used_money,
					qrcode_url: this.dataDetails.qrcode_url,
					state: this.dataDetails.state,
					pay_type: 40, // 数字人名币
					callback_id: this.dataDetails.callback_id,
					
				}
				if (isEmpty(this.dataDetails.account, "请输入钱包编号")) {
					return
				}
				if (isEmpty(this.dataDetails.true_name, "请输入姓名")) {
					return
				}
				if (isEmpty(this.dataDetails.telephone, "请输入电话号码")) {
					return
				}
				if (isEmpty(this.dataDetails.year_total_money, "请输入年累计限额")) {
					return
				}
				if (isEmpty(this.dataDetails.eve_used_money, "请输入日限额")) {
					return
				}
				this.$api.addAlipay(data).then(res => {
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()

					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}else{
				let data = {
					id: this.dataDetails.id,
					true_name: this.dataDetails.true_name,
					account: this.dataDetails.account,
					bank_name: this.dataDetails.bank_name,
					telephone: this.dataDetails.telephone,
					year_total_money: this.dataDetails.year_total_money,
					eve_used_money: this.dataDetails.eve_used_money,
					qrcode_url: this.dataDetails.qrcode_url,
					state: this.dataDetails.state,
					callback_id: this.dataDetails.callback_id,
					pay_type: 40,
				}
				if (isEmpty(this.dataDetails.account, "请输入钱包编号")) {
					return
				}
				if (isEmpty(this.dataDetails.true_name, "请输入真实姓名")) {
					return
				}
				if (isEmpty(this.dataDetails.telephone, "请输入电话号码")) {
					return
				}
				if (isEmpty(this.dataDetails.year_total_money, "请输入年累计限额")) {
					return
				}
				if (isEmpty(this.dataDetails.eve_used_money, "请输入日限额")) {
					return
				}
				// if (isEmpty(this.listIn, "请选择支付类型")) {
				// 	return
				// }
				this.$api.editAlipay(data).then(res => {
					if (res.status == 1) {
						this.$message({
							type: 'success',
							message: res.msg
						})
						this.close()
						this.getData()
					} else {
						this.$message({
							type: 'error',
							message: res.msg
						})
					}
				})
			}
			// let data = {
			// 	qr_id: this.item.id,
			// 	note: this.item.note,
			// }
			// this.$api.editStoreQr(data).then(res => {
			// 	if (res.status == 1) {
			// 		this.close()
			// 		this.alipayList()
			// 		this.$message({
			// 			type: 'success',
			// 			message: res.msg
			// 		})
			// 	} else {
			// 		this.$message({
			// 			type: 'error',
			// 			message: res.msg
			// 		})
			// 	}
			// })

		},
		changeAliState(e) {
			console.log(e,'==okl')
			this.dataDetails.state= e?0:1
			console.log(this.dataDetails.state,'this.dataDetails.state')
		},
		resolveQR(event) {
			const result = this.getQrUrl(event.file)
			result.then(res => {
				if (res.data) {
					this.dataDetails.qrcode_url = res.data
					this.$message({
						type: 'success',
						message:'识别二维码成功!'
					})
				} else {
					this.$message({
						type: 'error',
						message:'识别二维码失败, 请重新上传!'
					})
				}
			}).catch(err => {
				console.log(err, '识别异常')
			})
		},

		changeImg(file, fileList) {
			this.fileList = [file.raw]
			this.resolveQR(file.raw)

		},
		getQrUrl(file) {
			var url = null;
			if (window.createObjectURL) { // basic
				url = window.createObjectURL(file);
			} else if (window.URL) { // mozilla(firefox)
				url = window.URL.createObjectURL(file);
			} else if (window.webkitURL) { // webkit or chrome
				url = window.webkitURL.createObjectURL(file);
			}
			this.fileurl = url
			// 初始化
			const qr = new QrCode()
			// 解析二维码，返回promise
			return qr.decodeFromImage(url)
		},
		showBigImg(){
			//查看大图
			if(this.fileurl && this.fileurl !=''){
				this.innerVisibleImg = true
			}			
		},
		setAlipayState(state){
				let data = {
					callback_id:this.dataDetails.callback_id,
					state:state
				}
				
				this.$api.setAlipayState(data).then(res => {
					if (res.status == 1) {
						this.$local.toast(res.msg);
						this.$tool.back()
					} else {
						this.$local.toast(res.msg);
					}
				})
				
			},
		opened(){
			// let doc = document.getElementById('myDialog')
			// var domName = document.querySelector('#myDialog .el-dialog');
			// 	domName.style.background = 'transparent'
			// 	domName.style['box-shadow'] = 'none'
		}


	},
	watch: {

		total(val, old) {
			this.$nextTick(() => {
				let topNav = document.getElementsByClassName('topNav')[0].offsetHeight;
				let threeDirectoryBox = document.getElementsByClassName('threeDirectoryBox')[0].offsetHeight;
				let queryBox = document.getElementsByClassName('queryBox')[0].offsetHeight;
				let pageBox = document.getElementsByClassName('pageBox')[0].offsetHeight;
				let documentHeight = document.documentElement.clientHeight
				this.height = documentHeight - topNav - 20 - pageBox - 20 - queryBox - threeDirectoryBox - 40
			})
		}
	},

};
</script>

<style scoped="scoped" lang="scss">
@import '@/style/variables.scss';

.dataKey {
	display: inline-block;
	flex: 0 0 100px;
	text-align: left;
}

.qrIcon {
	display: inline-block;
	background: url(../../assets/image/qr.png);
	width: 30px;
	height: 30px;
	background-size: 100% 100%;
}

.errorDiv {
	white-space: nowrap;
	z-index: 2;
	position: absolute;
	top: 0;
	font-size: 10px;
	color: $danger
}

#uploadImg {
	display: none;
}

.upload-img {
	width: 100px;
	height: 100px;
}

.popup {
	position: fixed;
	left: 0;
	top: 0;
	display: none;
	background: rgba(0, 0, 0, 0.5);
	width: 100%;
	height: 100%;
	z-index: 9999;

	&.show {
		display: flex;
		justify-content: center;
		align-items: center;
	}

	.qrcode {
		display: inline-block;

		img {
			width: 400px;
			height: 400;
			background-color: #fff; //设置白色背景色
			padding: 20px; // 利用padding的特性，挤出白边
			box-sizing: border-box;
		}
	}
}
.image-dialog{
	display: flex;
	width: 100%;
	align-items: center;
	justify-content: center;
}
</style>
